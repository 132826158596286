import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrEditCompanyView',
  computed: {
    ...mapState('company', ['companies'])
  }
})
export default class GtrEditCompanyView extends Vue {
  data () {
    return {
      submitting: false,
      company: {
        owner: {
          uuid: null
        }
      },
      uuid: this.$route.params.uuid,
      owners: []
    }
  }

  async mounted () {
    const response = await this.$store.dispatch('adminUser/fetchAdminUsers')
    if (response) {
      response.map((user: any) => {
        this.$data.owners.push({
          value: user.uuid,
          text: user.name
        })
      })
    }
    const company: any = this.$store.state.company.company
    if (company) {
      this.$data.company = this._.cloneDeep(company)
    } else {
      await this.$store.dispatch('company/fetchCompany', this.$data.uuid)
      if (this.$store.state.company.company) {
        this.$data.company = this._.cloneDeep(this.$store.state.company.company)
      }
    }
  }

  async submit () {
    const form = (this.$refs.editCompanyForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          uuid: this.$data.uuid,
          data: {
            name: this.$data.company.name,
            logo: this.$data.company.logo,
            owner_uuid: this.$data.company.owner.uuid
          }
        }
        const logo = this.$data.company.newLogo
        if (logo instanceof File === true) {
          payload.data.uploadNewLogo = true
          payload.data.logo = this.$data.company.newLogo
        } else {
          payload.data.uploadNewLogo = false
        }
        await this.$store.dispatch('company/editCompany', payload)
        Container.get(Notification).success('Company successfully updated.')
        this.$bus.$emit('gtr-company-edit')
        this.$router.push({
          name: 'level-two.company.info',
          params: {
            uuid: this.$data.uuid
          }
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }
}
